import React from 'react';
import { defineMessages } from 'react-intl';
import { useMessages } from '@mobble/i18n';
import * as Yup from 'yup';
import { Color } from '@mobble/colors';
import {
  availableSortOptions,
  findMob,
  getMobDisplayName,
  sortAnyContainingMobs,
  type Mob,
} from '@mobble/models/src/model/Mob';
import { paddockForMob, type Paddock } from '@mobble/models/src/model/Paddock';
import { type ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { toISO8601 } from '@mobble/shared/src/core/Date';

import {
  FormBuilder,
  FormBuilderFieldProps,
  type FormBuilderProps,
} from '../Form/FormBuilder';
import {
  InputInventoryItems,
  // validateInputInventoryItems,
} from '../Misc/InputInventoryItems';
import { MobCard } from '../Mob/MobCard';
import { Box } from '../../Components/Layout/Box';

import { useInputInventoryItems } from './useInputInventoryItems';
import { MobActionEditFormValues } from './MobActionEditForm';

import styles from './mobActionCreateForm.scss';
import { InputContainer } from '@src/stories/Components/UX/InputContainer';

const ConnectedInventoryItems = (
  props: FormBuilderFieldProps<
    MobActionCreateFormValues | MobActionEditFormValues
  >
) => {
  const inputProps = useInputInventoryItems(props);
  return <InputInventoryItems {...inputProps} />;
};

export interface MobActionCreateFormProps {
  propertyTypes: ConfiguredPropertyType[];
  mobActions: { value: string; label: string }[];
  mobs: Mob[];
  paddocks: Paddock[];
  initialValues: Partial<MobActionCreateFormValues>;
  //
  error?: string;
  loading?: boolean;
  onAddMobAction: (label: string) => Promise<void>;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: MobActionCreateFormValues) => void;
}

export interface MobActionCreateFormValues {
  name: string;
  date: string;
  mobIds: string[];
  count?: number;
  action_type: string;
  inventory_items: string;
  note: string;
}

const messages = defineMessages({
  addNewActionType: {
    defaultMessage: 'Add a new action type',
    description: 'mob_action.create.form.action_type.add.placeholder.label',
  },
});

export const MobActionCreateForm: React.FC<MobActionCreateFormProps> = ({
  propertyTypes,
  mobActions,
  mobs,
  paddocks,
  initialValues,
  error,
  loading,
  onAddMobAction,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const strings = useMessages(messages);
  const getMobName = getMobDisplayName(mobs);
  const getPaddockForMob = paddockForMob(paddocks);

  const initialMobCount = (initialValues?.mobIds || [])
    .map(findMob(mobs))
    .reduce<number>((sum, mob) => (mob ? sum + mob.size : sum), 0);

  const mobOptions = mobs.map((mob) => {
    const paddock = getPaddockForMob(mob.id);

    return {
      entity: mob,
      value: mob.id,
      label: getMobName(mob.id) || '',
      labelExtra: `${mob.size}`,
      description: `${paddock?.name} ${mob.classes.join(' ')} ${mob.ages.join(
        ' '
      )}}`,
      component: <MobCard propertyTypes={[]} mob={mob} paddock={paddock} />,
    };
  });

  const form: FormBuilderProps<MobActionCreateFormValues> = {
    i18nRootKey: 'mob_action.create.form',
    className: styles.mobActionCreateForm,
    flex: true,
    fields: [
      {
        name: 'mobIds',
        type: 'select-multiple',
        options: mobOptions,
        sortOptions: availableSortOptions,
        sortFunction: sortAnyContainingMobs((o) => o.entity)({ paddocks }),
        initialValue: initialValues?.mobIds,
        required: true,
        onChange: (formValues) => {
          const mobCount = formValues.mobIds
            .map(findMob(mobs))
            .reduce<number>((sum, mob) => (mob ? sum + mob.size : sum), 0);
          return {
            ...formValues,
            count: mobCount,
          };
        },
      },
      {
        name: 'selected_mobs_preview',
        type: 'custom',
        containerComponent: false,
        component: ({ values }) => {
          if (!values.mobIds || values.mobIds.length === 0) {
            return null;
          }
          return (
            <InputContainer>
              <Box
                background={Color.LightGrey}
                className={styles.selectedMobPreviewWrapper}
              >
                {values.mobIds.map((mobId) => {
                  const mob = findMob(mobs)(mobId);
                  if (!mob) {
                    return null;
                  }
                  return (
                    <MobCard
                      key={mobId}
                      propertyTypes={propertyTypes}
                      paddock={paddockForMob(paddocks)(mob)}
                      mob={mob}
                    />
                  );
                })}
              </Box>
            </InputContainer>
          );
        },
      },
      {
        name: 'count',
        type: 'number',
        initialValue: initialMobCount || '',
        show: (formValues) => formValues.mobIds?.length === 1,
        validation: ({ formValues }) => {
          const maxCount = (formValues?.mobIds || [])
            .map(findMob(mobs))
            .reduce<number>((sum, mob) => (mob ? sum + mob.size : sum), 0);
          return Yup.number().max(maxCount).nullable();
        },
      },
      {
        name: 'date',
        type: 'date',
        required: true,
        initialValue: toISO8601(),
      },
      {
        name: 'action_type',
        type: 'select',
        options: mobActions,
        required: true,
        addNew: {
          onSubmit: onAddMobAction,
          placeholder: strings.addNewActionType,
        },
      },
      {
        name: 'inventory_items',
        type: 'custom',
        component: ConnectedInventoryItems,
        containerComponent: false,
        // validation: validateInputInventoryItems,
      },
      {
        name: 'note',
        type: 'textarea',
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
