import React from 'react';
import { useI18n } from '@mobble/i18n';
import { useSetting, useSettings } from '@mobble/store/src/hooks';
import {
  type Paddock,
  availableSortOptions,
} from '@mobble/models/src/model/Paddock';
import { getPaddocksInSamePaddockGroupAsPaddock } from '@mobble/models/src/model/PaddockGroup';
import { SortSetting } from '@mobble/shared/src/core/Sort';
import { usePaddocksView } from '@mobble/shared/src/hooks/usePaddocksView';
import { findPaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';

import { useAccessHelper } from '@src/hooks/useAccessHelper';
import { useMyLocation } from '@src/hooks/useMyLocation';
import { toPath } from '@src/interfaces/Routing';

import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { PaddockCard } from '@src/stories/Views/Paddock/PaddockCard';
import {
  EntitiesViewer,
  type EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { makePaddocksTableColumns } from '@src/stories/Views/Paddock/List/paddockTableColumns';
import { Box } from '@src/stories/Components/Layout/Box';
import { ActionSheetOnPaddockSelect } from './ActionSheetOnPaddockSelect';
import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';
import { StockingUnit } from '@mobble/models/src/model/Settings';

export const PaddocksHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const { hasAccessToRoute } = useAccessHelper();
  const displayActivity = hasAccessToRoute(ROUTE_NAME.SUMMARY_EVENTS_LIST);

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Paddocks',
        description: 'screen.title.paddocks',
      })}
      actions={
        displayActivity
          ? [
              {
                icon: 'activity',
                href: toPath(ROUTE_NAME.SUMMARY_EVENTS_LIST),
              },
            ]
          : []
      }
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Paddocks: React.FC = () => {
  const { formatMessage } = useI18n();
  const { location } = useMyLocation();
  const { settings } = useSettings();
  const stockingUnit = settings.stockingUnit as StockingUnit;

  const {
    Prelude,
    propertyId,
    paddocks,
    paddockGroupedStockingRates,
    paddockGroups,
    mobs,
    tasks,
    paddockGeometries,
    properties,

    applyFilter,
    applySort,
    filterItems,
    handleSelectPaddock,
    refresh,
    selectedPaddock,
    summaryCounter,
  } = usePaddocksView({ EntitySliceFactoryPrelude, location });

  if (Prelude) {
    return Prelude;
  }

  const viewerProps: EntitiesViewerProps<Paddock> = {
    provider: paddocks,
    filterItems,
    applyFilter,
    sortOptions: () => availableSortOptions,
    applySort,
    summaryCounter,
    onEmpty: formatMessage({
      description: 'paddocks.list.empty.add',
      defaultMessage: 'Add a paddock below',
    }),
    onRefresh: refresh,
    onClickTableRow: handleSelectPaddock,
    renderEntityCard: (paddock, { sort }) => {
      const sortingByDistance = Boolean(
        sort.find((a: SortSetting) => a.column === 'distance')
      );
      return (
        <Box spacing={1}>
          <PaddockCard
            paddockGroupedStockingRates={paddockGroupedStockingRates.entities}
            paddocksInSamePaddockGroup={getPaddocksInSamePaddockGroupAsPaddock(
              paddockGroups.entities,
              paddocks.entities
            )(paddock.id)}
            mobs={mobs.entities}
            tasks={tasks.entities}
            paddock={paddock}
            paddockGeometry={findPaddockGeometry(paddockGeometries.entities)(
              paddock.id
            )}
            onClick={handleSelectPaddock}
            propertyTypes={properties.selected?.types || []}
            showDistanceFrom={sortingByDistance ? location : undefined}
          />
        </Box>
      );
    },
    tableColumns: makePaddocksTableColumns({
      propertyTypes: properties.selected?.types ?? [],
      mobs: mobs.entities,
      paddockGeometries: paddockGeometries.entities,
      paddockGroupedStockingRates: paddockGroupedStockingRates.entities,
      paddocks: paddocks.entities,
      paddockGroups: paddockGroups.entities,
      settings,
      stockingUnit,
      formatMessage,
    }),
  };

  return (
    <>
      <EntitiesViewer key={propertyId} {...viewerProps} />

      {selectedPaddock ? (
        <ActionSheetOnPaddockSelect
          paddock={selectedPaddock}
          onClose={() => handleSelectPaddock(null)}
        />
      ) : null}
    </>
  );
};

export default {
  name: ROUTE_NAME.PADDOCKS_LIST,
  header: PaddocksHeader,
  component: Paddocks,
};
