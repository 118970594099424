import React, { ComponentPropsWithoutRef, useEffect } from 'react';
import classNames from 'classnames/bind';

import Icon, { IconName } from '../Icon';

import { Spinner } from '@src/stories/Components/UI/Spinner';

import styles from './Button.scss';
const cx = classNames.bind(styles);

export type ButtonSize = 'small' | 'medium';

export interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  /**
   * The intent of the button, affects the style
   */
  intent?: 'primary' | 'secondary' | 'destructive' | 'neutral';

  /**
   * Displays the loading state
   */
  loading?: boolean;

  /**
   * Disables the button
   */
  disabled?: boolean;

  /**
   * The label of the button, alternatively pass as `children`
   */
  label?: string;

  /**
   * The size of the button
   */
  size?: ButtonSize;

  /**
   * The icon to display before the label
   */
  icon?: IconName;

  /**
   * The icon to display after the label
   */
  iconAfter?: IconName;

  /**
   * Displays the button as an outline style
   */
  outline?: boolean;

  /**
   * Displays a circular button, should be used with `icon`
   */
  round?: boolean;

  /**
   * Makes the button flex, it will fill the available space
   */
  flex?: boolean;

  /**
   * Click handler
   */
  onClick?: () => void;
}

/**
 * Button component
 */
const Button: React.FC<ButtonProps> = ({
  intent = 'primary',
  disabled,
  loading,
  label,

  outline,
  round,
  flex,
  size = 'medium',

  icon,
  iconAfter,

  children,
  onClick,
  className,
  ...others
}) => {
  const rootClasses = cx(
    {
      Button: true,
      [intent]: true,
      [size]: true,
      loading: loading,
      flex: flex,
      outline: outline,
      round: round,
    },
    className
  );

  const hasLabel = Boolean(label || children);

  /**
   * Warning messages
   */
  const ariaLabel = others['aria-label'];
  const ariaLabelledBy = others['aria-labelledby'];
  useEffect(() => {
    if (!hasLabel && !ariaLabel && !ariaLabelledBy) {
      console.warn(
        'Button: neither "label", "aria-label" or "aria-labelledby" property is provided. Please provide one.'
      );
    }
  }, [hasLabel, ariaLabel, ariaLabelledBy]);

  const handleOnClick = () => {
    if (!disabled && !loading && typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <button
      type="button"
      aria-busy={loading}
      disabled={disabled}
      onClick={handleOnClick}
      className={rootClasses}
      {...others}
    >
      {loading && <Spinner />}

      {icon && (
        <Icon
          name={icon}
          size={hasLabel ? 'small' : 'medium'}
          className={styles.icon}
        />
      )}

      {hasLabel && <div className={styles.label}>{children || label}</div>}

      {iconAfter && (
        <Icon name={iconAfter} size="small" className={styles.iconAfter} />
      )}
    </button>
  );
};

export default Button;
