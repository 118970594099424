import React, { useState } from 'react';
import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import { type GroupedProperties } from '@mobble/models/src/model/Property';

import { useDialog } from '@src/hooks/useDialog';

import * as ROUTE_NAME from '@src/screens/config/routeNames';

import { type ListProps } from '@src/components/List';

import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { DrawerItem } from '@src/stories/Components/UX/Drawer/DrawerItem';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';

import styles from './propertySelector.scss';

export interface PropertySelectorProps {
  collapsed?: boolean;
  groupedProperties: GroupedProperties;
  selectedPropertyId?: string;
  canCreate?: boolean;
  onSelect: (value: string) => void;
  onOpen: () => void;
}

export const PropertySelector: React.FC<PropertySelectorProps> = ({
  collapsed,
  groupedProperties,
  selectedPropertyId,
  canCreate,
  onSelect,
  onOpen,
}) => {
  const { alert, close } = useDialog();
  const { formatMessage } = useI18n();
  const [expanded, setExpanded] = useState(false);

  const onToggle = () => {
    if (!expanded) {
      onOpen();
    }
    setExpanded(!expanded);
  };

  const onItemSelect = (vale: string) => {
    onSelect(vale);
    setExpanded(false);
  };

  const handleNoAccessClick = () => {
    alert(
      formatMessage({
        description: 'Create new property access denied title',
        defaultMessage: 'Only organisation owners can create new properties',
      }),
      formatMessage({
        description: 'Create new property access denied message',
        defaultMessage:
          'Contact your organisation owner if you would like to create a new property for this account.',
      }),
      [
        {
          label: formatMessage({
            description: 'Create new property access denied close button text',
            defaultMessage: 'Close',
          }),
          outline: true,
          onClick: () => {
            close();
          },
        },
      ]
    );
  };

  const selectedLabel = groupedProperties.reduce((label, group) => {
    return (
      group.properties.find((p) => p.id === selectedPropertyId)?.name ?? label
    );
  }, '');

  const renderSectionHeader = (section: any) => {
    return (
      <Box spacing={2} background={Color.White}>
        <Text bold variant="body" color={Color.DarkGrey}>
          {section.title}
        </Text>
      </Box>
    );
  };

  const renderItem = (item) => {
    const selected = selectedPropertyId === item.value;
    return (
      <Clickable fullWidth href={() => onItemSelect(item.value)}>
        <Box
          style={{ flex: 1 }}
          spacing={2}
          background={selected ? Color.AlmostWhite : Color.White}
        >
          <HStack>
            <Spacer x={1} />
            <Text variant="body" color={selected ? Color.Green : Color.Black}>
              {item.label}
            </Text>
          </HStack>
        </Box>
      </Clickable>
    );
  };

  const items = groupedProperties.map((group) => ({
    title: group.name,
    data: group.properties.map((p) => ({
      label: p.name,
      value: p.id,
    })),
  }));

  const listProps: ListProps = {
    items,
    renderItem,
    renderSectionHeader,
    keyExtractor: (_, index) => String(index),
  };

  const Footer = (
    <>
      <Clickable
        icon="plus-circle"
        iconSize="normal"
        color={Color.DarkGrey}
        href={
          canCreate ? ROUTE_NAME.MODAL_PROPERTY_CREATE : handleNoAccessClick
        }
        onClick={() => {
          setExpanded(false);
        }}
      >
        <Text color={Color.DarkGrey}>
          {formatMessage({
            description: 'screen.title.property_create',
            defaultMessage: 'Create new property',
          })}
        </Text>
      </Clickable>
    </>
  );

  return (
    <Box className={styles.PropertySelector}>
      <DrawerItem
        label={selectedLabel}
        bold
        icon="farm"
        iconAfter={expanded ? 'chevron-up' : 'chevron-down'}
        href={onToggle}
        collapsed={collapsed}
      />
      <ModalFlyUp
        title={formatMessage({
          description: 'property_selector.modal.title',
          defaultMessage: 'Select property',
        })}
        isOpen={expanded}
        onClose={() => {
          setExpanded(false);
        }}
        listProps={listProps}
        footer={Footer}
      />
    </Box>
  );
};
