import React from 'react';
import { defineMessages } from 'react-intl';
import { useI18n, useMessages } from '@mobble/i18n';
import {
  mapAssetsTypes,
  type MapAsset,
  type MapAssetType,
  mapAssetToPoints,
  mapAssetTypeToGeometryFeatureType,
} from '@mobble/models/src/model/MapAsset';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { type Point } from '@mobble/models/src/model/MapGeometry';
import { mapAssetTypeToMode } from '../../Map/Plugins/Creator';

export interface MapAssetEditFormProps {
  mapAsset: MapAsset;
  error?: string;
  loading?: boolean;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: MapAssetEditFormValues) => void;
}

export interface MapAssetEditFormValues {
  name: string;
  description?: string;
  type?: string;
  points?: Point[];
}

const messages = defineMessages({
  'map_assets.type.bore': {
    id: 'map_assets.type.bore',
    defaultMessage: 'Bore',
  },
  'map_assets.type.dam': {
    id: 'map_assets.type.dam',
    defaultMessage: 'Dam',
  },
  'map_assets.type.electric-fence': {
    id: 'map_assets.type.electric-fence',
    defaultMessage: 'Electric fence',
  },
  'map_assets.type.electric-fence-unit': {
    id: 'map_assets.type.electric-fence-unit',
    defaultMessage: 'Electric fence unit',
  },
  'map_assets.type.feeder': {
    id: 'map_assets.type.feeder',
    defaultMessage: 'Feeder',
  },
  'map_assets.type.gate': {
    id: 'map_assets.type.gate',
    defaultMessage: 'Gate',
  },
  'map_assets.type.hazard': {
    id: 'map_assets.type.hazard',
    defaultMessage: 'Hazard',
  },
  'map_assets.type.other-line': {
    id: 'map_assets.type.other-line',
    defaultMessage: 'Other line',
  },
  'map_assets.type.other-point': {
    id: 'map_assets.type.other-point',
    defaultMessage: 'Other point',
  },
  'map_assets.type.pipeline': {
    id: 'map_assets.type.pipeline',
    defaultMessage: 'Pipeline',
  },
  'map_assets.type.road': {
    id: 'map_assets.type.road',
    defaultMessage: 'Road',
  },
  'map_assets.type.shed': {
    id: 'map_assets.type.shed',
    defaultMessage: 'Shed',
  },
  'map_assets.type.silo': {
    id: 'map_assets.type.silo',
    defaultMessage: 'Silo',
  },
  'map_assets.type.tank': {
    id: 'map_assets.type.tank',
    defaultMessage: 'Tank',
  },
  'map_assets.type.water-trough': {
    id: 'map_assets.type.water-trough',
    defaultMessage: 'Water trough',
  },
});

export const MapAssetEditForm: React.FC<MapAssetEditFormProps> = ({
  mapAsset,
  error,
  loading,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();

  const typeToModeI18n = (type: MapAssetType) => {
    switch (mapAssetTypeToGeometryFeatureType(type)) {
      case 'Point':
        return formatMessage({
          defaultMessage: 'Point',
          description: 'map_asset.mode.point',
        });
      case 'LineString':
        return formatMessage({
          defaultMessage: 'Line',
          description: 'map_asset.mode.line_string',
        });
      default:
        return '';
    }
  };

  const mapAssetTypeOptions = mapAssetsTypes.map((value) => ({
    label: strings[`map_assets.type.${value}`],
    labelExtra: typeToModeI18n(value),
    value,
  }));

  const form: FormBuilderProps<MapAssetEditFormValues> = {
    i18nRootKey: 'map_assets.map_asset.edit.form',
    flex: true,
    fields: [
      {
        name: 'name',
        type: 'text',
        required: true,
        initialValue: mapAsset.name,
      },
      {
        name: 'description',
        type: 'textarea',
        initialValue: mapAsset.description,
      },
      {
        name: 'type',
        type: 'select',
        required: true,
        options: mapAssetTypeOptions,
        initialValue: mapAsset.map.type,
      },
      {
        name: 'points',
        type: 'map-creator',
        required: true,
        mapCreator: {
          options: {
            single: mapAssetTypeToMode(mapAsset.map.type) === 'point',
          },
        },
        initialValue: mapAssetToPoints(mapAsset),
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
