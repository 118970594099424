import React from 'react';
import { defineMessages } from 'react-intl';
import { useI18n, useMessages } from '@mobble/i18n';
import { Color } from '@mobble/colors';
import { type Mob } from '@mobble/models/src/model/Mob';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import { ConfiguredPropertyTypeGroup } from '@mobble/models/src/model/Property';
import { formatDate } from '@mobble/shared/src/core/Date';
import { useSetting } from '@mobble/store/src/hooks/settings';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { Box } from '@src/stories/Components/Layout/Box';
import { InfoRow, type InfoRowProps } from '@src/stories/Components/UI/InfoRow';
import { Badge } from '@src/stories/Components/UI/Badge';
import { HStack } from '@src/stories/Components/Layout/Stack';
import {
  formatStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { getStockingUnitI18n } from '@src/stories/Components/Locale/LocaleStockingUnit';

export interface MobInfoTableProps {
  mob: Mob;
  paddock?: Paddock;
  onClickPaddock: (paddock: Paddock) => void;
}

const messages = defineMessages({
  ages: {
    id: 'mob.info.table.row.ages.label',
    defaultMessage: 'Ages',
  },
  breed: {
    id: 'mob.info.table.row.breed.label',
    defaultMessage: 'Breed',
  },
  gender: {
    id: 'mob.info.table.row.gender.label',
    defaultMessage: 'Gender',
  },
  name: {
    id: 'mob.info.table.row.name.label',
    defaultMessage: 'Name',
  },
  number: {
    id: 'mob.info.table.row.number.label',
    defaultMessage: 'Head',
  },
  paddock: {
    id: 'mob.info.table.row.paddock.label',
    defaultMessage: 'Paddock',
  },
  safedate: {
    id: 'mob.info.table.row.safedate.label',
    defaultMessage: 'Safe Date',
  },
  type: {
    id: 'mob.info.table.row.type.label',
    defaultMessage: 'Type',
  },
  classes: {
    id: 'mob.info.table.row.classes.label',
    defaultMessage: 'Classes',
  },
});

export const MobInfoTable: React.FC<MobInfoTableProps> = ({
  mob,
  paddock,
  onClickPaddock,
}) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();
  const dateFormat = useSetting('dateFormat') as string;
  const properties = useProperties();

  const stockingUnit = useSetting('stockingUnit') as StockingUnit;

  const livestockTypeId = properties.selected?.types.find(
    (pt) =>
      pt.group === ConfiguredPropertyTypeGroup.livestockType &&
      pt.type === mob.type
  )?.id;

  const colorForYear = (year: number) => {
    return properties.selected?.types.find(
      (pt) =>
        pt.parentId === livestockTypeId &&
        pt.group === ConfiguredPropertyTypeGroup.tag &&
        pt.type === String(year)
    )?.color;
  };

  const rows: InfoRowProps[] = [
    {
      label: strings.name,
      value: `${mob.breed} ${mob.gender}`,
    },
    {
      label: strings.number,
      value: mob.size,
    },
    ...(paddock
      ? [
          {
            label: strings.paddock,
            value: paddock.name,
            href: () => {
              onClickPaddock(paddock);
            },
          },
        ]
      : []),
    {
      label: strings.type,
      value: mob.type,
    },
    {
      label: strings.gender,
      value: mob.gender,
    },
    {
      label: strings.breed,
      value: mob.breed,
    },
    {
      label: formatMessage(getStockingUnitI18n(stockingUnit)),
      value: formatStockingRate(mob.DSE, stockingUnit),
    },
    {
      label: strings.safedate,
      value: mob.safeDate ? formatDate(mob.safeDate, dateFormat) : ' - ',
    },
    {
      label: strings.ages,
      childAfter: (
        <HStack wrap>
          {mob.ages.map((age) => (
            <Box key={age} spacing={{ bottom: 1, right: 1 }}>
              <Badge
                key={age}
                label={String(age)}
                color={colorForYear(age)}
                textVariant="body"
              />
            </Box>
          ))}
        </HStack>
      ),
    },
    ...(mob.classes.length
      ? [
          {
            label: strings.classes,
            childAfter: (
              <HStack wrap>
                {mob.classes.map((classPill) => (
                  <Box key={classPill} spacing={{ bottom: 1, right: 1 }}>
                    <Badge
                      label={classPill}
                      textVariant="body"
                      color={Color.White}
                    />
                  </Box>
                ))}
              </HStack>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      {rows.map((row) => (
        <InfoRow key={row.label} {...row} />
      ))}
    </>
  );
};
