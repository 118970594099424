import React from 'react';
import * as Yup from 'yup';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { Purchase } from '@mobble/models/src/model/Purchase';
import { type Paddock, paddockForMob } from '@mobble/models/src/model/Paddock';
import { roundNumberTo } from '@mobble/shared/src/core/Number';
import { Color } from '@mobble/colors';
import { Box } from '../../Components/Layout/Box';
import { MobCard } from '../Mob/MobCard';
import { useI18n } from '@mobble/i18n';
import { InputContainer } from '@src/stories/Components/UX/InputContainer';

export interface PurchaseEditFormProps {
  paddocks: Paddock[];
  purchase: Purchase;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (formValues: PurchaseEditFormValues) => void;
}

export interface PurchaseEditFormValues {
  number: number;
  price_per_head: string | number;
  total_price: string | number;
  date: any;
  seller: string;
  notes: string;
}

export const PurchaseEditForm: React.FC<PurchaseEditFormProps> = ({
  paddocks,
  purchase,
  loading,
  onCancel,
  onSubmit,
}) => {
  const { translate } = useI18n();
  const form: FormBuilderProps<PurchaseEditFormValues> = {
    flex: true,
    i18nRootKey: 'purchase.edit.form',
    fields: [
      {
        name: 'selected_mobs_preview',
        type: 'custom',
        containerComponent: false,
        component: () => {
          return (
            <InputContainer>
              <Box background={Color.LightGrey} spacing={2}>
                <MobCard
                  propertyTypes={[]}
                  paddock={paddockForMob(paddocks)(purchase.mob)}
                  mob={purchase.mob}
                />
              </Box>
            </InputContainer>
          );
        },
      },
      {
        name: 'number',
        type: 'number',
        required: true,
        initialValue: purchase.mob.size,
        validation: Yup.number()
          .integer(
            translate({
              key: 'summaries.purchase.edit.form.number.error.label',
            }) || ''
          )
          .required(),
        min: 1,
        onChange: (values) => {
          return {
            ...values,
            total_price: values.price_per_head
              ? Number(values.price_per_head) * (values?.number || 1)
              : '',
          };
        },
      },
      {
        name: 'price_per_head',
        type: 'float',
        initialValue: purchase.pricePerHeadCents / 100,
        required: true,
        min: 0,
        step: 0.01,
        onChange: (values) => {
          return {
            ...values,
            total_price: values.price_per_head
              ? Number(values.price_per_head) * (values?.number || 1)
              : '',
          };
        },
      },
      {
        name: 'total_price',
        type: 'float',
        initialValue:
          Number(purchase.pricePerHeadCents / 100) * purchase.mob.size,
        required: true,
        min: 0,
        step: 0.01,
        onChange: (values) => {
          return {
            ...values,
            price_per_head: values.total_price
              ? roundNumberTo(2)(
                  Number(values.total_price) / (values?.number || 1)
                )
              : '',
          };
        },
      },
      {
        name: 'date',
        type: 'date',
        initialValue: String(purchase.date),
        required: true,
      },
      {
        name: 'seller',
        type: 'text',
        initialValue: purchase.sellerName,
      },
      {
        name: 'notes',
        type: 'textarea',
        initialValue: purchase.notes ?? '',
      },
    ],
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
