import React from 'react';
import { defineMessages } from 'react-intl';
import { useMessages } from '@mobble/i18n';
import * as Yup from 'yup';
import { Color } from '@mobble/colors';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { type MobAction } from '@mobble/models/src/model/MobAction';
import { type Paddock, paddockForMob } from '@mobble/models/src/model/Paddock';
import { type ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import {
  findMob,
  type Mob,
  getMobDisplayName,
  sortAnyContainingMobs,
  availableSortOptions,
} from '@mobble/models/src/model/Mob';

import {
  FormBuilder,
  type FormBuilderProps,
  type FormBuilderFieldProps,
} from '../Form/FormBuilder';
import { InputInventoryItems } from '../Misc/InputInventoryItems';
import { Box } from '@src/stories/Components/Layout/Box';
import { MobCard } from '../Mob/MobCard';
import { useInputInventoryItems } from './useInputInventoryItems';

import styles from './mobActionCreateForm.scss';
import { MobActionCreateFormValues } from './MobActionCreateForm';
import { InputContainer } from '@src/stories/Components/UX/InputContainer';

export interface MobActionEditFormProps {
  propertyTypes: ConfiguredPropertyType[];
  mobAction: MobAction;
  mobActions: { value: string; label: string }[];
  mobs: Mob[];
  paddocks: Paddock[];
  //
  error?: string;
  loading?: boolean;
  onAddMobAction: (label: string) => Promise<void>;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: MobActionEditFormValues) => void;
}

export interface MobActionEditFormValues {
  date: string;
  mobIds: string[];
  count?: number | null;
  action_type: string;
  inventory_items: string;
  note: string;
}

const messages = defineMessages({
  addNewActionType: {
    defaultMessage: 'Add a new action type',
    description: 'mob_action.edit.form.action_type.add.placeholder.label',
  },
});

const ConnectedInventoryItems = (
  props: FormBuilderFieldProps<
    MobActionCreateFormValues | MobActionEditFormValues
  >
) => {
  const inputProps = useInputInventoryItems(props);
  return <InputInventoryItems {...inputProps} />;
};

export const MobActionEditForm: React.FC<MobActionEditFormProps> = ({
  propertyTypes,
  mobAction,
  mobActions,
  mobs,
  paddocks,
  error,
  loading,
  onAddMobAction,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const strings = useMessages(messages);

  const getPaddockForMob = paddockForMob(paddocks);

  const allMobs = [
    ...mobs,
    ...(mobAction.mobs ?? []).filter((m) => !findMob(mobs)(m.id)),
  ];
  const getMobName = getMobDisplayName(allMobs);

  const mobOptions = allMobs.map((mob) => {
    const paddock = getPaddockForMob(mob.id);
    return {
      entity: mob,
      value: mob.id,
      label: getMobName(mob.id) || '',
      labelExtra: `${mob.size}`,
      description: `${paddock?.name} ${mob.classes.join(' ')} ${mob.ages.join(
        ' '
      )}}`,
      component: <MobCard propertyTypes={[]} mob={mob} paddock={paddock} />,
    };
  });

  const form: FormBuilderProps<MobActionEditFormValues> = {
    i18nRootKey: 'mob_action.edit.form',
    className: styles.mobActionCreateForm,
    flex: true,
    fields: [
      {
        name: 'mobIds',
        type: 'select-multiple',
        options: mobOptions,
        sortOptions: availableSortOptions,
        sortFunction: sortAnyContainingMobs((o) => o.entity)({ paddocks }),
        initialValue: mobAction.mobIds,
        required: true,
        onChange: (formValues) => {
          const mobCount = formValues.mobIds
            .map(findMob(mobs))
            .reduce<number>((sum, mob) => (mob ? sum + mob.size : sum), 0);
          return {
            ...formValues,
            count: mobCount,
          };
        },
      },
      {
        name: 'selected_mobs_preview',
        type: 'custom',
        containerComponent: false,
        component: ({ values }) => {
          if (!values.mobIds || values.mobIds.length === 0) {
            return null;
          }
          return (
            <InputContainer>
              <Box
                background={Color.LightGrey}
                className={styles.selectedMobPreviewWrapper}
              >
                {values.mobIds.map((mobId) => {
                  const mob = findMob(mobs)(mobId);
                  if (mob) {
                    return (
                      <MobCard
                        key={mobId}
                        propertyTypes={propertyTypes}
                        paddock={paddockForMob(paddocks)(mob)}
                        mob={mob}
                      />
                    );
                  }

                  const deletedMob = mobAction.mobs?.find(
                    (m) => m.id === mobId
                  );
                  if (deletedMob) {
                    return (
                      <MobCard
                        key={mobId}
                        propertyTypes={propertyTypes}
                        paddock={deletedMob.snapshot?.paddockName}
                        mob={deletedMob}
                      />
                    );
                  }

                  return null;
                })}
              </Box>
            </InputContainer>
          );
        },
      },
      {
        name: 'count',
        type: 'number',
        initialValue: mobAction.count ?? '',
        validation: ({ formValues }) => {
          const maxCount = (formValues?.mobIds ?? [])
            .map(findMob(allMobs))
            .reduce<number>((sum, mob) => (mob ? sum + mob.size : sum), 0);
          return Yup.number().max(maxCount).nullable();
        },
        show: (formValues) => formValues?.mobIds?.length === 1,
      },
      {
        name: 'date',
        type: 'date',
        required: true,
        initialValue: toISO8601(mobAction.date),
      },
      {
        name: 'action_type',
        type: 'select',
        options: mobActions,
        required: true,
        initialValue: mobAction.type,
        addNew: {
          onSubmit: onAddMobAction,
          placeholder: strings.addNewActionType,
        },
      },
      {
        name: 'inventory_items',
        type: 'custom',
        initialValue: JSON.stringify(mobAction.appliedInventoryItems),
        component: ConnectedInventoryItems,
        containerComponent: false,
        // validation: validateInputInventoryItems,
      },
      {
        name: 'note',
        type: 'textarea',
        initialValue: mobAction.note,
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
