import React, { useState } from 'react';
import { defineMessages } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useI18n, useMessages } from '@mobble/i18n';
import {
  Casualty,
  findCasualtiesForPaddock,
} from '@mobble/models/src/model/Casualty';
import { polygonToArea } from '@mobble/models/src/model/MapGeometry';
import { Mob, findMob, findMobs } from '@mobble/models/src/model/Mob';
import { Paddock } from '@mobble/models/src/model/Paddock';
import { PaddockAction } from '@mobble/models/src/model/PaddockAction';
import { findPaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';
import {
  findPaddockGroupForPaddock,
  getPaddocksInSamePaddockGroupAsPaddock,
} from '@mobble/models/src/model/PaddockGroup';
import { findPaddockGroupGeometry } from '@mobble/models/src/model/PaddockGroupGeometry';
import {
  findPaddockGroupedStockingRate,
  toTotals,
} from '@mobble/models/src/model/PaddockGroupedStockingRate';
import {
  Task,
  findPendingTasksForPaddock,
} from '@mobble/models/src/model/Task';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useCasualties,
  useMobs,
  usePaddock,
  usePaddockActions,
  usePaddockGeometries,
  usePaddockGroupGeometries,
  usePaddockGroupedStockingRates,
  usePaddockGroups,
  usePaddockStockingRates,
  usePaddocks,
  useProperties,
  useTasks,
} from '@mobble/store/src/hooks';

import { Alert } from '@src/components';

import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';

import { ChartHistoricPropertyLivestock } from '@src/stories/Components/Charts/LivestockHistoricGraph/LivestockHistoricGraph';
import { Box } from '@src/stories/Components/Layout/Box';

import { CasualtiesViewer } from '@src/stories/Views/Casualty/List/CasualtiesViewer';
import { Provider } from '@src/stories/Views/Entities/types';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { TabBar } from '@src/stories/Views/Misc/TabBar';
import { MobsViewer } from '@src/stories/Views/Mob/List/MobsViewer';
import { ChartMonthlyCasualtiesByMob } from '@src/stories/Views/Paddock/Charts/ChartCasualtiesOverTime';
import { ChartLivestockTotals } from '@src/stories/Views/Paddock/Charts/ChartLivestockTotals';
import { PaddockInfoTable } from '@src/stories/Views/Paddock/PaddockInfoTable';
import { PaddockMap } from '@src/stories/Views/Paddock/PaddockMap';
import { PaddockActionsViewer } from '@src/stories/Views/PaddockAction/List/PaddockActionsViewer';
import { TasksViewer } from '@src/stories/Views/Task/List/TasksViewer';

import { HistoricEvents } from '@src/screens/HistoricEvents/HistoricEvents';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

import styles from './paddockDetail.scss';

const messages = defineMessages({
  info: {
    defaultMessage: 'Info',
    description: 'screen.title.paddock_detail_info',
  },
  mobs: {
    defaultMessage: 'Mobs',
    description: 'screen.title.paddock_detail_mobs',
  },
  tasks: {
    defaultMessage: 'Tasks',
    description: 'screen.title.paddock_detail_tasks',
  },
  history: {
    defaultMessage: 'History',
    description: 'screen.title.paddock_detail_history',
  },
  paddockActions: {
    defaultMessage: 'Paddock actions',
    description: 'screen.title.summary_paddock_actions',
  },
  casualties: {
    defaultMessage: 'Casualties',
    description: 'mob_detail.casualties.title',
  },
  statusDeletedHeading: {
    defaultMessage: 'Paddock deleted',
    description: 'Deleted paddock status heading',
  },
  statusDeletedMessage: {
    defaultMessage: 'You are unable to edit deleted paddocks.',
    description: 'Deleted paddock status message',
  },
});

export const PaddockDetailHeader: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const paddock = usePaddock(paddockId);
  const paddocks = usePaddocks(
    paddock.entity?.propertyId ?? properties.selected?.id
  );

  const isDeleted = paddock.entity?.status === 'deleted';

  const quickLinks = paddocks.entities
    .map((p) => ({
      title: p.name,
      href: toPath(ROUTE_NAME.PADDOCK_DETAIL, { paddockId: p.id }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <ScreenHeader
      title={
        paddock?.entity?.name ||
        formatMessage({
          defaultMessage: 'Paddock details',
          description: 'screen.title.paddock_detail',
        })
      }
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Paddocks',
            description: 'screen.title.paddocks',
          }),
          href: ROUTE_NAME.PADDOCKS_LIST,
        },
      ]}
      quickLinks={quickLinks}
      actions={
        !isDeleted
          ? [
              {
                icon: 'edit',
                href: toPath(ROUTE_NAME.PADDOCK_DETAIL_EDIT, { paddockId }),
              },
            ]
          : []
      }
      onGoBack={goBack}
    />
  );
};

type PaddockDetailMobileTab = 'info' | 'mobs' | 'tasks' | 'history';

export const PaddockDetail: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const strings = useMessages(messages);
  const linkTo = useLinking();
  const { hash } = useLocation();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddock = usePaddock(paddockId, propertyId);

  const casualties = useCasualties(propertyId);
  const mobs = useMobs(propertyId);
  const paddockActions = usePaddockActions(propertyId);
  const paddockGeometries = usePaddockGeometries(propertyId);
  const paddockGroupGeometries = usePaddockGroupGeometries(propertyId);
  const paddockGroups = usePaddockGroups(propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddockStockingRates = usePaddockStockingRates(propertyId, paddockId);
  const tasks = useTasks(propertyId);

  const [activeTab, setActiveTab] = useState<PaddockDetailMobileTab>(
    (hash.slice(1) as PaddockDetailMobileTab) || 'info'
  );

  const isDeleted = paddock.entity?.status === 'deleted';

  const paddockActionsOfPaddock: Provider<PaddockAction> = {
    ...paddockActions,
    entities: paddockActions.entities.filter((action) =>
      action.paddocks.find((paddock) => paddock.paddockId === paddockId)
    ),
  };

  const casualtiesForPaddock: Provider<Casualty> = {
    ...casualties,
    entities: findCasualtiesForPaddock(casualties.entities)(paddockId),
  };

  const tasksForPaddock: Provider<Task> = {
    ...tasks,
    entities: findPendingTasksForPaddock(tasks.entities)(paddockId),
  };

  const paddockGroup = findPaddockGroupForPaddock(paddockGroups.entities)(
    paddockId
  );
  const paddockGeometry = findPaddockGeometry(paddockGeometries.entities)(
    paddockId
  );

  const paddocksInSamePaddockGroup =
    paddock.entity && paddockGroups.entities
      ? getPaddocksInSamePaddockGroupAsPaddock(
          paddockGroups.entities,
          paddocks.entities
        )(paddockId)
      : null;

  const getMobs = findMobs(mobs.entities);

  const paddocksExcludingCurrent = paddocksInSamePaddockGroup
    ? paddocksInSamePaddockGroup.filter((pdk) => pdk.id !== paddockId)
    : [];

  const paddockGroupMobs = paddocksExcludingCurrent
    ? getMobs(paddocksExcludingCurrent.map((pdk) => pdk.mobs).flat())
    : null;

  const paddockGroupGeometry = paddockGroup
    ? findPaddockGroupGeometry(paddockGroupGeometries.entities)(paddockGroup.id)
    : null;

  const paddockTotalArea = paddockGeometry
    ? polygonToArea(paddockGeometry.polygon)
    : undefined;

  const mobsOnPaddock = {
    ...mobs,
    entities: paddock?.entity?.mobs.length
      ? ([
          ...(paddock.entity as Paddock).mobs
            .map(findMob(mobs.entities))
            .filter(Boolean),
          ...paddockGroupMobs,
        ] as Mob[])
      : [],
  };

  const paddockGroupedStockingRates = usePaddockGroupedStockingRates(
    properties.selected?.id
  );

  const paddockGroupedStockingRate = findPaddockGroupedStockingRate(
    paddockGroupedStockingRates.entities
  )(paddockId);

  const paddockGroupedStockingRateTotals = paddockGroupedStockingRate
    ? toTotals(paddockGroupedStockingRate)
    : null;

  const tabs = ['info', 'mobs', 'tasks', 'history'].map((value) => ({
    label: strings[value],
    value,
    isActive: value === activeTab,
    onClick: (tab) => {
      setActiveTab(tab);
      window.location.hash = `#${tab}`;
    },
  }));

  const paddockMap = paddockGeometry ? (
    <PaddockMap
      propertyTypes={properties.selected?.types ?? []}
      paddocks={[paddock.entity as Paddock]}
      paddockGeometries={paddockGeometries.entities}
      paddockGroupGeometry={paddockGroupGeometry}
      mobs={mobs.entities}
      onMaximise={() => {
        linkTo(ROUTE_NAME.MAP_OVERVIEW, {
          goto: `paddock--${paddockId}`,
        });
      }}
    />
  ) : null;

  const HistoricEventsRendered = (
    <HistoricEvents
      title={strings['history']}
      className={styles.paddockTabHistoryTimeline}
      asList
      extFilter={{ paddockId }}
    />
  );

  const MobsViewerRenderdd = (
    <MobsViewer title={strings['mobs']} showTitle provider={mobsOnPaddock} />
  );

  useEntitiesRefresher(
    [
      // nested MobsViewer will refresh mobs
      casualties,
      paddockActions,
      paddockGeometries,
      paddockGroupGeometries,
      paddocks,
      paddock,
      paddockStockingRates,
      tasks,
    ],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, paddock.prelude],
    required: [properties.selected],
    notFound: [paddock.entity],
    type: 'paddock',
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <>
      <TabBar className={styles.tabBar} tabs={tabs} />

      <Box className={styles.paddockDetailLayout}>
        {paddockGeometry && (
          <Box className={styles.paddockMapDesktop}>{paddockMap}</Box>
        )}

        <Alert
          open={isDeleted}
          variant="warning"
          title={strings.statusDeletedHeading}
          message={strings.statusDeletedMessage}
        />

        <Box
          className={[
            styles.paddockTabInfo,
            styles.tab,
            activeTab === 'info' ? styles.active : null,
          ]}
        >
          <a id="info" />
          {paddockGeometry && (
            <Box className={styles.paddockMapMobile}>{paddockMap}</Box>
          )}
          <Box className={styles.paddockInfoTabTable}>
            <PaddockInfoTable
              propertyTypes={properties.selected?.types ?? []}
              paddock={paddock.entity as Paddock}
              mobs={mobs.entities}
              paddocksInSamePaddockGroup={paddocksExcludingCurrent}
              paddockGroupedStockingRateTotals={
                paddockGroupedStockingRateTotals
              }
              paddockTotalArea={paddockTotalArea}
            />
          </Box>
        </Box>

        <Box
          className={[
            styles.paddockTabHistory,
            styles.tab,
            activeTab === 'history' ? styles.active : null,
          ]}
        >
          <a id="history" />
          {HistoricEventsRendered}
        </Box>

        <Box
          className={[
            styles.paddockTabMobs,
            styles.tab,
            activeTab === 'mobs' ? styles.active : null,
          ]}
        >
          <a id="mobs" />
          {MobsViewerRenderdd}
        </Box>

        <Box className={styles.paddockTabChartLivestockTotals}>
          <ChartLivestockTotals mobs={mobsOnPaddock.entities} />
        </Box>

        <Box className={styles.paddockTabChartHistoricLivestock}>
          <ChartHistoricPropertyLivestock
            stockingRates={paddockStockingRates.entities}
          />
        </Box>

        <Box className={[styles.paddockActionsTable, styles.tab]}>
          <PaddockActionsViewer
            title={strings['paddockActions']}
            provider={paddockActionsOfPaddock}
          />
        </Box>

        <Box
          className={[
            styles.paddockTabTasks,
            styles.tab,
            activeTab === 'tasks' ? styles.active : null,
          ]}
        >
          <a id="tasks" />
          <TasksViewer
            title={strings['tasks']}
            showTitle
            provider={tasksForPaddock}
          />
        </Box>

        <Box className={styles.paddockTabCasualties}>
          <CasualtiesViewer
            title={strings['casualties']}
            showTitle
            provider={casualtiesForPaddock}
          />
        </Box>

        {casualtiesForPaddock.entities.length > 0 && (
          <Box className={styles.paddockTabChartCasualties}>
            <ChartMonthlyCasualtiesByMob
              casualties={casualtiesForPaddock.entities}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default {
  name: ROUTE_NAME.PADDOCK_DETAIL,
  header: PaddockDetailHeader,
  component: PaddockDetail,
};
