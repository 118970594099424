import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { theme } from '@src/stories/Components/Charts/config';
import { Color, colorToHex } from '@mobble/colors';
import { Text } from '@src/stories/Components/UI/Text';
import styles from './chartLivestockHistoryGraph.scss';
import { useI18n } from '@mobble/i18n/src';

const AVG_LINE_OFFSET = -7;

export const ChartLivestockHistoryGraph = ({
  data,
  dataAverage,
  dataAverageByYmd,
  maxY,
}: {
  data: any;
  dataAverage: any;
  dataAverageByYmd: any;
  maxY: any;
}) => {
  const AverageLine = ({ lineGenerator, xScale, yScale }) => {
    if (dataAverage.length === 0) {
      return null;
    }

    return (
      <path
        key={'average'}
        d={lineGenerator(
          dataAverage.map((d) => ({
            x: xScale(d.x) + AVG_LINE_OFFSET,
            y: yScale(d.y),
          }))
        )}
        fill="none"
        stroke={Color.Red}
        strokeWidth={4}
      />
    );
  };

  return (
    <ResponsiveLine
      animate={true}
      margin={{ top: 40, right: 10, bottom: 50, left: 40 }}
      data={data}
      pointSize={0}
      colors={{ datum: 'color' }}
      lineWidth={3}
      curve="stepAfter"
      enableArea={true}
      areaOpacity={0.6}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        useUTC: false,
        precision: 'day',
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: 'linear',
        stacked: true,
        min: 0,
        max: maxY + maxY * 0.1,
      }}
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        AverageLine,
        'slices',
        'points',
        'mesh',
        'legends',
      ]}
      axisBottom={{
        format: '%b %Y',
        tickRotation: -45,
      }}
      theme={theme}
      legends={[
        {
          toggleSerie: true,
          anchor: 'top',
          translateY: -30,
          direction: 'row',
          itemWidth: 100,
          itemHeight: 14,
          itemTextColor: colorToHex(Color.Black),
          symbolShape: 'circle',
        },
      ]}
      enablePointLabel={false}
      enableSlices={'x'}
      enableCrosshair={true}
      sliceTooltip={({ slice }) => {
        const dmy = slice.points[0].data.xFormatted;
        const avg = dataAverageByYmd[dmy]?.y.toFixed(2);
        const total = slice.points
          .reduce((acc, point) => acc + Number(point.data.yFormatted), 0)
          .toFixed(2);
        return <GraphTooltip slice={slice} avg={avg} total={total} />;
      }}
    />
  );
};

const GraphTooltip = ({
  slice,
  avg,
  total,
}: {
  slice: any;
  avg: any;
  total: any;
}) => {
  const { formatMessage } = useI18n();
  return (
    <div className={styles.tooltip}>
      <Text tagName="h1" variant="small" bold>
        {slice.points[0].data.xFormatted}
      </Text>

      <table border={0} cellPadding={0} cellSpacing={0}>
        <tbody>
          {slice.points.map((point) => (
            <tr key={point.id}>
              <td>
                <div
                  className={styles.colorCircle}
                  style={{ backgroundColor: point.color }}
                />
              </td>
              <td>
                <Text variant="small">{point.serieId}</Text>
              </td>
              <td>
                <Text variant="small" bold align="right">
                  {point.data.yFormatted}
                </Text>
              </td>
            </tr>
          ))}
          <br />
          <tr>
            <td>
              <div className={styles.colorCircle} />
            </td>
            <td>
              <Text variant="small">
                {formatMessage({
                  defaultMessage: 'total',
                  description: 'chart.historic-livestock.total',
                })}
              </Text>
            </td>
            <td>
              <Text variant="small" bold align="right">
                {total}
              </Text>
            </td>
          </tr>
        </tbody>

        <tfoot>
          {avg && (
            <tr>
              <td>
                <div
                  className={styles.colorCircle}
                  style={{ backgroundColor: Color.Red }}
                />
              </td>
              <td>
                <Text variant="small">
                  {formatMessage({
                    defaultMessage: 'Av. (12 month)',
                    description: 'chart.historic-livestock.average',
                  })}
                </Text>
              </td>
              <td>
                <Text variant="small" bold align="right">
                  {avg}
                </Text>
              </td>
            </tr>
          )}
        </tfoot>
      </table>
    </div>
  );
};
