import React, {
  ComponentPropsWithoutRef,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { defineMessages } from 'react-intl';
import Fuse from 'fuse.js';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames/bind';

import { useI18n, useMessages } from '@mobble/i18n';
import { SortOption, SortSetting } from '@mobble/shared/src/core/Sort';

import { useOnClickOutside } from '@src/hooks/useOnClickOutside';
import { useMediaQuery, MediaQuerySize } from '@src/hooks/useMediaQuery';

import Button from '@src/components/Button';
import Dialog from '@src/components/Dialog';
import Icon from '@src/components/Icon';
import Input from '@src/components/Input';
import List from '@src/components/List';
import Text from '@src/components/Text';

import { InlineOption } from '@src/stories/Components/UX/InlineOption';

import styles from './ListSelect.scss';
const cx = classNames.bind(styles);

const messages = defineMessages({
  selectInputPlaceholder: {
    defaultMessage: 'Select an option',
    description: 'generic.components.ux.inline_select.placeholder',
  },
  filterInputPlaceholder: {
    defaultMessage: 'Filter available options',
    description: 'generic.components.ux.inline_select.filter.placeholder',
  },
  addNewInputPlaceholder: {
    defaultMessage: 'Custom name',
    description: 'generic.input.inline_select.add_new.placeholder.label',
  },
  addNewButtonLabel: {
    defaultMessage: 'Add',
    description: 'generic.input.inline_select.add_new.button.label',
  },
  sortOptionsTitle: {
    defaultMessage: 'Sorting options',
    description: 'entities.entities_sort.title',
  },
  distance_from_paddock: {
    defaultMessage: 'Distance from paddock',
    description: 'entities.entities_sort.options.distance_from_paddock.label',
  },
  distance: {
    defaultMessage: 'Distance from me',
    description: 'entities.entities_sort.options.distance.label',
  },
  head_asc: {
    defaultMessage: 'Head (least)',
    description: 'entities.entities_sort.options.head_asc.label',
  },
  head_desc: {
    defaultMessage: 'Head (most)',
    description: 'entities.entities_sort.options.head_desc.label',
  },
  name_asc: {
    defaultMessage: 'Name (A-Z)',
    description: 'entities.entities_sort.options.name_asc.label',
  },
  name_desc: {
    defaultMessage: 'Name (Z-A)',
    description: 'entities.entities_sort.options.name_desc.label',
  },
  name_yards_asc: {
    defaultMessage: 'Name (A-Z) (Yards first)',
    description: 'entities.entities_sort.options.name_yards_asc.label',
  },
  paddock_name_asc: {
    defaultMessage: 'Paddock name (A-Z)',
    description: 'entities.entities_sort.options.paddock_name_asc.label',
  },
  paddock_name_desc: {
    defaultMessage: 'Paddock name (Z-A)',
    description: 'entities.entities_sort.options.paddock_name_desc.label',
  },
  size_asc: {
    defaultMessage: 'Size (smallest)',
    description: 'entities.entities_sort.options.size_asc.label',
  },
  size_desc: {
    defaultMessage: 'Size (largest)',
    description: 'entities.entities_sort.options.size_desc.label',
  },
  days_grazed_asc: {
    defaultMessage: 'Days grazed/rested (ascending)',
    description: 'entities.entities_sort.options.days_grazed_asc.label',
  },
  days_grazed_desc: {
    defaultMessage: 'Days grazed/rested (descending)',
    description: 'entities.entities_sort.options.days_grazed_desc.label',
  },
  type: {
    defaultMessage: 'Type',
    description: 'entities.entities_sort.options.type.label',
  },
});

export interface ListSelectProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  /**
   * id for the input
   */
  id: string;

  /**
   * Displayed as the placeholder when no options are selected
   * also used as the heading for the options dropdown
   */
  placeholder?: string;

  /**
   * Disables the input
   */
  disabled?: boolean;

  /**
   * Hides the header of the dropdown
   */
  hideHeader?: boolean;

  /**
   * List of options to display
   */
  options: ListSelectOption[];

  /**
   * Sorting options, `sortFunction` must also be provided
   */
  sortOptions?: SortOption[];

  /**
   * Custom sort functions
   * If provided, this will be used instead of the default sort function
   */
  sortFunction?: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];

  /**
   * Default sort settings to be applied
   */
  defaultSortSettings?: SortSetting[];

  /**
   * allows multiple options to be selected
   */
  multiple?: boolean;

  /**
   * Use to set initial expanded state of the dropdown
   * To keep in sync with the component's internal state, use the `onBlur` prop
   */
  expanded?: boolean;

  /**
   * Displays a text input that filters the options
   */
  showFilter?: boolean;

  /**
   * changes menu's direction to open upwards
   * only relevant for large screens as small always open from the bottom
   */
  menuPlacement?: 'bottom' | 'top';

  /**
   * Override the default label when options are selected
   */
  selectionLabel?: string;

  /**
   * Placeholder text for the add new input - requires 'onAddNew' prop
   */
  addNewPlaceholder?: string;

  /**
   * Label for the add new button - requires `onAddNew` prop
   */
  addNewButtonLabel?: string;

  /**
   * Callback for adding a new option
   */
  onAddNew?: (value: string) => Promise<void>;

  /**
   * Triggers when the select menu closes
   */
  onBlur?: () => void;

  /**
   * returns the currently selected values
   */
  onChange: (values: (string | number)[]) => void;

  /**
   * Sort settings changed - used to persist sort setting to preferences
   */
  onSortSettingsChanged?: (settings: SortSetting[]) => void;
}

export type LabelLike = number | string | React.ReactNode | JSX.Element;

// TODO: are all these required?
export interface ListSelectOption {
  id?: string;
  label: LabelLike;
  value: number | string;
  labelExtra?: LabelLike;
  description?: LabelLike;
  component?: React.ReactNode | JSX.Element;
  type?: 'checkbox';
  color?: string;
  selected?: boolean;
  condensed?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

/**
 * ListSelect displays a button that triggers a dropdown menu
 * on large screen and a fly-up modal on smaller screens.
 */
const ListSelect: React.FC<ListSelectProps> = ({
  id,
  options,
  sortOptions,
  sortFunction,
  defaultSortSettings,
  selectionLabel,
  placeholder,
  expanded,
  showFilter,
  multiple,
  onChange,
  onBlur,
  addNewPlaceholder,
  addNewButtonLabel,
  onAddNew,
  disabled = false,
  hideHeader,
  menuPlacement = 'bottom',
  onSortSettingsChanged,
}) => {
  const { formatMessage } = useI18n();
  const strings = useMessages(messages);
  const size = useMediaQuery();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(expanded);
  const [filterText, setFilterText] = useState('');
  const [showSortOptions, setShowSortOptions] = useState(false);

  const [selectedSortSettings, setSelectedSortSettings] = useState<
    SortSetting[]
  >(
    (() => {
      if (defaultSortSettings) {
        return defaultSortSettings;
      } else if (sortOptions) {
        return (sortOptions as any)[0]?.settings ?? [];
      }
      return [];
    })()
  );

  const [addNewState, setAddNewState] = useState({
    value: '',
    loading: false,
  });

  const isSmall = size === MediaQuerySize.Small;

  const rootClasses = cx({
    ListSelect: true,
    expanded: isOpen,
    menuTop: menuPlacement === 'top',
  });

  const handleRequestClose = () => {
    setFilterText('');
    setIsOpen(false);
  };

  const handleClickOutside = () => {
    if (isOpen && !showSortOptions) {
      setIsOpen(false);
    }
  };

  useOnClickOutside(ref, handleClickOutside);

  useEffect(() => {
    setIsOpen(expanded);
  }, [expanded]);

  const sortByLabel = (arr: ListSelectOption[]) => {
    return arr.sort((a, b) => {
      const aLabel = String(a.label ?? a.value);
      const bLabel = String(b.label ?? b.value);

      return aLabel.localeCompare(bLabel);
    });
  };

  const fuse = new Fuse(options, {
    keys: [
      { name: 'label', weight: 3 },
      { name: 'description', weight: 1 },
      { name: 'labelExtra', weight: 1 },
    ],
  });

  const processedOptions = filterText
    ? [...fuse.search(filterText).map((a) => a.item)]
    : sortFunction
    ? sortFunction(options, selectedSortSettings)
    : sortByLabel(options);

  // TODO: add select all button
  // const allSelected = processedOptions.every((o) => o.selected);
  const selectedOptions = processedOptions.filter((option) => option.selected);

  let defaultLabel;
  if (multiple) {
    if (selectedOptions.length > 3) {
      defaultLabel = formatMessage(
        {
          defaultMessage: '{count} selected',
          description: 'Select list selection label',
        },
        {
          count: selectedOptions.length,
        }
      );
    } else {
      defaultLabel = selectedOptions.map((o) => o.label).join(', ');
    }
  } else {
    defaultLabel =
      selectedOptions.length > 0
        ? selectedOptions[0]?.label ?? selectedOptions[0]?.value
        : strings.selectInputPlaceholder;
  }

  const handleSelectSortSettings = (a: SortSetting[]) => {
    setSelectedSortSettings(a);

    if (onSortSettingsChanged) {
      onSortSettingsChanged(a);
    }
  };

  const handleChangeFilterText = (text: string) => {
    setFilterText(text);
  };

  const handleToggleExpanded = () => {
    setIsOpen(!isOpen);

    if (onBlur && !isOpen) {
      onBlur();
    }
  };

  const handleOptionItemClick = (option: ListSelectOption) => {
    const newOptions = multiple
      ? options.map((o) =>
          o.value === option.value ? { ...o, selected: !o.selected } : o
        )
      : options.map((o) =>
          o.value === option.value
            ? { ...o, selected: true }
            : { ...o, selected: false }
        );

    onChange(newOptions.filter((o) => o.selected).map((o) => o.value));

    if (!multiple) {
      handleToggleExpanded();
    }
  };

  const handleAddNewInputKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && addNewState.value) {
      e.preventDefault();
      handleAddNew(addNewState.value);
    }
  };

  const handleAddNew = (value: string) => {
    if (!onAddNew) {
      return;
    }
    setAddNewState({ value, loading: true });
    onAddNew(value).then(() => {
      setAddNewState({ value: '', loading: false });

      if (!multiple) {
        handleToggleExpanded();
      }
    });
  };

  const headerFilterInput = showFilter ? (
    <div className={styles.filterTextWrapper}>
      <Input
        id={`${id}-filter`}
        showClear
        placeholder={strings.filterInputPlaceholder}
        value={filterText}
        onChange={handleChangeFilterText}
      />
    </div>
  ) : null;

  const headerSortButton = sortOptions ? (
    <Button
      aria-label={strings.sortOptionsTitle}
      icon="sort-asc"
      size="small"
      round
      onClick={() => {
        setShowSortOptions(true);
      }}
    />
  ) : null;

  const headerItems = [headerFilterInput, headerSortButton].filter(
    (a) => a !== null
  ) as JSX.Element[];

  const additionalHeader =
    headerItems.length > 0 ? (
      <div className={styles.filterWrapper}>{headerItems}</div>
    ) : null;

  const renderSortOption = (item: SortOption) => {
    return (
      <li key={item.name}>
        <InlineOption
          type="checkbox"
          value={item.name}
          label={strings[item.name]}
          selected={isEqual(item.settings, selectedSortSettings)}
          onClick={() => {
            handleSelectSortSettings(item.settings);
          }}
        />
      </li>
    );
  };

  const renderListItem = (item: ListSelectOption) => {
    return (
      <InlineOption
        {...item}
        key={item.value}
        type={multiple ? 'checkbox' : undefined}
        onClick={() => handleOptionItemClick(item)}
      />
    );
  };

  return (
    <div ref={ref} className={rootClasses}>
      <ListSelectButton
        id={id}
        defaultLabel={defaultLabel}
        placeholder={placeholder}
        disabled={disabled}
        isOpen={isOpen}
        hasSelectedOptions={selectedOptions.length > 0}
        selectionLabel={selectionLabel}
        onToggleExpanded={handleToggleExpanded}
      />

      <Dialog
        id={`${id}-dialog`}
        isOpen={isOpen}
        isModal={isSmall}
        drawerPosition={isSmall ? 'bottom' : undefined}
        shouldCloseOnOutsideClick={isSmall ? true : false}
        header={!hideHeader ? { heading: placeholder } : undefined}
        onRequestClose={handleRequestClose}
      >
        {additionalHeader}
        <List
          role="listbox"
          items={processedOptions}
          renderItem={renderListItem}
        />
        {onAddNew ? (
          <div className={styles.addNewWrapper}>
            <Input
              id={`${id}-add-new`}
              type="text"
              value={addNewState.value}
              disabled={addNewState.loading}
              placeholder={addNewPlaceholder ?? strings.addNewInputPlaceholder}
              onKeyUp={handleAddNewInputKeyUp}
              onChange={(value) => setAddNewState({ ...addNewState, value })}
            >
              <Button
                disabled={!addNewState.value}
                loading={addNewState.loading}
                label={addNewButtonLabel ?? strings.addNewButtonLabel}
                onClick={() => handleAddNew(addNewState.value)}
              />
            </Input>
          </div>
        ) : null}
      </Dialog>

      {sortOptions ? (
        <Dialog
          isOpen={showSortOptions}
          onRequestClose={() => setShowSortOptions(false)}
          header={{
            heading: strings.sortOptionsTitle,
          }}
        >
          <ul role="listbox">
            {sortOptions.map((item) => {
              return renderSortOption(item);
            })}
          </ul>
        </Dialog>
      ) : null}
    </div>
  );
};

export const ListSelectButton: React.FC<{
  id: string;
  defaultLabel?: string | React.ReactNode; // if not passed, can set placeholder and selectionLabel
  placeholder?: string;
  disabled?: boolean;
  isOpen?: boolean;
  hasSelectedOptions?: boolean;
  selectionLabel?: string;
  onToggleExpanded: () => void;
}> = ({
  id,
  defaultLabel,
  placeholder,
  disabled,
  isOpen,
  hasSelectedOptions,
  selectionLabel,
  onToggleExpanded,
}) => {
  const contentClasses = cx({
    content: true,
    selected: hasSelectedOptions,
    disabled: disabled,
    expanded: isOpen,
  });

  return (
    <button
      id={id}
      type="button"
      aria-haspopup="dialog"
      aria-expanded={isOpen}
      disabled={disabled}
      className={styles.ListSelectButton}
      onClick={onToggleExpanded}
    >
      <div className={contentClasses}>
        <Text tagName="span">
          {hasSelectedOptions
            ? selectionLabel || defaultLabel
            : placeholder || defaultLabel}
        </Text>

        <Icon size="small" name={isOpen ? 'chevron-up' : 'chevron-down'} />
      </div>
    </button>
  );
};

export default ListSelect;
