import React, { useState } from 'react';
import isEqual from 'lodash/isEqual';
import flatMap from 'lodash/flatMap';

import { useI18n } from '@mobble/i18n';
import {
  type Mob,
  findMobs,
  toMobDisplayName,
  findMob,
} from '@mobble/models/src/model/Mob';
import { findPaddock, type Paddock } from '@mobble/models/src/model/Paddock';
import { type Task } from '@mobble/models/src/model/Task';
import { type MapAsset } from '@mobble/models/src/model/MapAsset';
import { getPaddocksInSamePaddockGroupAsPaddock } from '@mobble/models/src/model/PaddockGroup';
import {
  useMobs,
  usePaddockGroups,
  usePaddocks,
  useProperties,
  useSettings,
} from '@mobble/store/src/hooks';

import { type OnClickMapItemEvent } from '@src/hooks/useMapProperties';
import { usePaddockCardFactory } from '@src/hooks/usePaddockCardFactory';

import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';
import { Box } from '@src/stories/Components/Layout/Box';
import { TaskCard } from '@src/stories/Views/Task/TaskCard';
import { PaddockMoveMobFormQuick } from '@src/stories/Views/Paddock/PaddockMoveMobFormQuick';
import { MobCard } from '@src/stories/Views/Mob/MobCard';
import { MapAssetCard } from '@src/stories/Views/MapAsset/MapAssetCard';
import { MobsSelectListModal } from '@src/stories/Views/Paddock/MobsSelectListModal';

export interface MapPortalModal {
  content: MapPortalContent;
  onClose: () => void;
  onRefresh: () => void;
}

export interface MapPortalContentBase {
  type: string;
  onClick: (ev?: OnClickMapItemEvent) => void;
}

export interface MapPortalContentPaddock extends MapPortalContentBase {
  type: 'paddock';
  paddock: Paddock;
}

export interface MapPortalContentMobs extends MapPortalContentBase {
  type: 'mobs';
  paddock: Paddock;
}

export interface MapPortalContentMob extends MapPortalContentBase {
  type: 'mob';
  mob: Mob;
}

export interface MapPortalContentTask extends MapPortalContentBase {
  type: 'task';
  task: Task;
}

export interface MapPortalContentMapAsset extends MapPortalContentBase {
  type: 'map-asset';
  mapAsset: MapAsset;
}

export interface MapPortalContentMoveMob extends MapPortalContentBase {
  type: 'move-mob';
  fromPaddock: Paddock;
  toPaddock: Paddock;
}

export type MapPortalContent =
  | null
  | MapPortalContentPaddock
  | MapPortalContentMobs
  | MapPortalContentMob
  | MapPortalContentTask
  | MapPortalContentMapAsset
  | MapPortalContentMoveMob;

const MapPortalComponent: React.FC<MapPortalModal> = ({
  content,
  onClose,
  onRefresh,
}) => {
  const { formatMessage } = useI18n();
  const paddockCardFactory = usePaddockCardFactory({});
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const mobs = useMobs(propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddockGroups = usePaddockGroups(propertyId);
  const [selectedMobs, setSelectedMobs] = useState<string[]>([]);
  const getMobs = findMobs(mobs.entities);
  const { mapMobsFilter = [] } = useSettings().settings;

  const selectedPaddockDetails =
    content?.type === 'mobs'
      ? (() => {
          const paddock = content?.paddock;

          const paddocksInSamePaddockGroup =
            paddock && paddockGroups.entities
              ? getPaddocksInSamePaddockGroupAsPaddock(
                  paddockGroups.entities,
                  paddocks.entities
                )(paddock.id)
              : null;

          const paddocksExcludingCurrent = paddocksInSamePaddockGroup
            ? paddocksInSamePaddockGroup.filter((pdk) => pdk.id !== paddock.id)
            : [];

          const paddockGroupMobs = paddocksExcludingCurrent
            ? getMobs(flatMap(paddocksExcludingCurrent.map((pdk) => pdk.mobs)))
            : null;

          const mobsOnPaddock = paddock?.mobs
            .map(findMob(mobs.entities))
            .filter(Boolean) as Mob[];

          return {
            paddockGroupMobs,
            mobsOnPaddock,
            paddock,
          };
        })()
      : null;

  if (!content) {
    return null;
  }

  const handleCloseModal = () => {
    setSelectedMobs([]);
    onClose();
  };

  const handleClickMob = (mob: Mob) => {
    if (selectedPaddockDetails?.paddock) {
      content.onClick({
        type: 'mob',
        paddock: selectedPaddockDetails?.paddock,
        mob,
      });
      handleCloseModal();
    }
  };

  const handleSelectMob = (mobIds: Mob['id'][]) => {
    setSelectedMobs(mobIds);
  };

  const renderTitle = (): undefined | string => {
    switch (content.type) {
      case 'paddock':
      case 'mobs':
        return content.paddock.name;
      case 'mob':
        return toMobDisplayName(content.mob);
      case 'task':
        return content.task.title;
      case 'map-asset':
        return content.mapAsset.name;
      case 'move-mob':
        return formatMessage({
          description: 'paddocks.paddock.move_mob.form.quick.title',
          defaultMessage: 'Move mob',
        });
      default:
        return undefined;
    }
  };

  const renderContent = () => {
    switch (content.type) {
      case 'paddock': {
        const paddock = findPaddock(paddocks.entities)(content?.paddock.id);

        return (
          <ModalFlyUp
            isOpen={true}
            onClose={handleCloseModal}
            title={renderTitle()}
          >
            <Box spacing={2}>
              {paddockCardFactory(paddock, () => {
                content.onClick();
                handleCloseModal();
              })}
            </Box>
          </ModalFlyUp>
        );
      }
      case 'mobs':
        return (
          <MobsSelectListModal
            title={renderTitle()}
            paddockId={selectedPaddockDetails.paddock.id}
            selectedMobs={selectedMobs}
            mobsFilter={mapMobsFilter}
            onSelectMob={handleSelectMob}
            onClickMob={handleClickMob}
            onClose={handleCloseModal}
          />
        );
      case 'mob':
        return (
          <ModalFlyUp
            isOpen={true}
            onClose={handleCloseModal}
            title={renderTitle()}
          >
            <Box spacing={2}>
              <MobCard
                propertyTypes={properties.selected?.types ?? []}
                mob={content.mob}
                href={() => {
                  content.onClick();
                  handleCloseModal();
                }}
              />
            </Box>
          </ModalFlyUp>
        );
      case 'task':
        return (
          <ModalFlyUp
            isOpen={true}
            onClose={handleCloseModal}
            title={renderTitle()}
          >
            <Box spacing={2}>
              <TaskCard
                task={content.task}
                onClick={() => {
                  content.onClick();
                  handleCloseModal();
                }}
              />
            </Box>
          </ModalFlyUp>
        );
      case 'map-asset':
        return (
          <ModalFlyUp
            isOpen={true}
            onClose={handleCloseModal}
            title={renderTitle()}
          >
            <Box spacing={2}>
              <MapAssetCard
                mapAsset={content.mapAsset}
                onClick={() => {
                  content.onClick();
                  handleCloseModal();
                }}
              />
            </Box>
          </ModalFlyUp>
        );
      case 'move-mob':
        return (
          <ModalFlyUp
            isOpen={true}
            onClose={handleCloseModal}
            title={renderTitle()}
          >
            <PaddockMoveMobFormQuick
              fromPropertyId={content.fromPaddock.propertyId}
              fromPaddockId={content.fromPaddock.id}
              toPaddockId={content.toPaddock.id}
              onReady={onRefresh}
            />
          </ModalFlyUp>
        );
      default:
        return null;
    }
  };

  return renderContent();
};

export const MapPortal = React.memo(MapPortalComponent, (a, b) => {
  return isEqual(a.content, b.content);
});
