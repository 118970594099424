import React from 'react';
import { Color as LegacyColor, colorToHex } from '@mobble/colors';
import { Color } from '@mobble/theme';

import styles from './spinner.scss';

export interface SpinnerProps extends React.ComponentPropsWithoutRef<'div'> {
  color?: Color | LegacyColor;
  progress?: number;
}

export const Spinner: React.FC<SpinnerProps> = ({
  color,
  progress,
  className,
  ...others
}) => {
  const step = progress ? Math.floor(progress * 8) : false;
  const classNames = [
    styles.spinner,
    step === false ? styles.animated : false,
    className,
  ]
    .filter(Boolean)
    .join(' ');

  const styleBlade = (index: number) => ({
    backgroundColor: colorToHex(color ?? Color.White),
    ...(step === false
      ? {}
      : {
          opacity: step > index ? 1 : 0.2,
        }),
  });

  return (
    <div role="progressbar" className={classNames} {...others}>
      {Array(8)
        .fill(null)
        .map((_, i: number) => (
          <span
            key={`blade=${i}`}
            style={styleBlade(i)}
            className={styles.spinnerBlade}
          />
        ))}
    </div>
  );
};
