import React from 'react';
import isEqual from 'lodash/isEqual';
import { Color, colorToHex } from '@mobble/colors';
import {
  ConfiguredPropertyType,
  ConfiguredPropertyTypeGroup,
} from '@mobble/models/src/model/Property';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import { toMobDisplayName, type Mob } from '@mobble/models/src/model/Mob';
import { dateIsInThePast } from '@mobble/shared/src/core/Date';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Card } from '@src/stories/Components/Layout/Card';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Badges } from '@src/stories/Components/UI/Badges';
import styles from './mobCard.scss';
import { useI18n } from '@mobble/i18n/src';
import { useSetting } from '@mobble/store/src/hooks';
import {
  formatStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { getStockingUnitI18n } from '@src/stories/Components/Locale/LocaleStockingUnit';

export interface MobCardProps {
  propertyTypes: ConfiguredPropertyType[];
  paddock?: Paddock | string;
  mob: Mob;
  href?: string | (() => void);
}

export const MobCardComponent: React.FC<MobCardProps> = ({
  propertyTypes,
  paddock,
  mob,
  href,
}) => {
  const { formatMessage } = useI18n();
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));
  const convertStockingRate = (stockingRate: number) =>
    formatStockingRate(stockingRate, stockingUnit);

  const livestockTypeId = propertyTypes.find(
    (pt) =>
      pt.group === ConfiguredPropertyTypeGroup.livestockType &&
      pt.type === mob.type
  )?.id;

  const filteredClasses = (mob.classes || []).filter(Boolean);

  const colorForYear = (year: number) => {
    return propertyTypes.find(
      (pt) =>
        pt.parentId === livestockTypeId &&
        pt.group === ConfiguredPropertyTypeGroup.tag &&
        pt.type === `${year}`
    )?.color;
  };

  const isSafe = mob.safeDate ? dateIsInThePast(mob.safeDate) : true;

  const topRight = (
    <Box>
      <HStack alignment="center">
        <Spacer flex />
        <Text align="right" bold variant="jumbo" color={Color.Black}>
          {mob.size}
        </Text>
        {!isSafe && (
          <>
            <Spacer x={0.5} />
            <Box className={styles.unSafeDot} />
          </>
        )}
      </HStack>
      <Text align="right" variant="body" color={Color.DarkGrey}>
        {formatMessage(
          {
            defaultMessage: '{STOCKING_UNIT} {STOCKING_RATE}',
            description: 'Stocking unit label',
          },
          {
            STOCKING_UNIT: stockingUnitLabel,
            STOCKING_RATE: convertStockingRate(mob.DSE),
          }
        )}
      </Text>
    </Box>
  );

  const title = () => (
    <Box className={styles.title}>
      <Text align="left" bold variant="card-title" color={Color.Black}>
        {toMobDisplayName(mob)}
      </Text>
      <Spacer y={0.5} />
      {paddock && (
        <Text color={Color.DarkGrey}>
          {typeof paddock === 'string' ? paddock : paddock.name}
        </Text>
      )}
    </Box>
  );

  return (
    <Clickable fullWidth flex href={href}>
      <Card title={title} color={colorToHex(Color.Purple)} topRight={topRight}>
        <HStack>
          <Box>
            <Box spacing={{ top: 0.5, right: 0, bottom: 0, left: 0 }}>
              <Badges
                label={{ key: 'mobs.list.mob.card.ages' }}
                badges={mob.ages.map((age) => ({
                  label: `${age}`,
                  color: colorForYear(age),
                }))}
              />
            </Box>

            {filteredClasses.length > 0 ? (
              <Box spacing={{ top: 0.5, right: 0, bottom: 0, left: 0 }}>
                <Badges
                  label={{ key: 'mobs.list.mob.card.classes' }}
                  badges={filteredClasses.map((key) => ({
                    label: key,
                    color: colorToHex(Color.AlmostWhite),
                  }))}
                />
              </Box>
            ) : null}
          </Box>
        </HStack>
      </Card>
    </Clickable>
  );
};

export const MobCard = React.memo(MobCardComponent, (a, b) => {
  return isEqual(a.mob, b.mob) && isEqual(a.paddock, b.paddock);
});
