import React, { useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from 'react-router-dom';
import { useAuthIsLoggedIn, useGetUser } from '@mobble/store/src/hooks/auth';
import { useProperties } from '@mobble/store/src/hooks/properties';
import * as ROUTE_NAME from './config/routeNames';
import { ACCESS_MATRIX } from '../screens/config/access';
import { useIsOnline } from '@src/hooks/useNetworkInformation';
import { UserRole } from '@mobble/models/src/model/User';
import stagingFavIcon from '@src/assets/favicon-staging.png';
import {
  AppLayout,
  useAppLayoutContext,
} from '@src/stories/Components/Layout/AppLayout';
import Screens from '@src/screens';
import { LoadingScreen } from '@src/stories/Views/Misc/LoadingScreen';
import { toPath } from '@src/interfaces/Routing';
import { ScreenRendererProps } from './config/types';
import { OfflineNoDataScreen } from '@src/stories/Views/Misc/OfflineNoDataScreen';
import { AppScreen } from '@src/stories/Components/Layout/AppScreen';
import { useSetting, useSettings } from '@mobble/store/src/hooks';
import { StockingUnit } from '@mobble/models/src/model/Settings';

enum AppRouteState {
  Unauthenticated = 'Unauthenticated',
  LoggedInSetUp = 'LoggedInSetUp',
  LoggedInUserHasNoDisplayName = 'LoggedInUserHasNoDisplayName',
  LoggedInReturningUser = 'LoggedInReturningUser',
  LoggedInOfflineNoData = 'LoggedInOfflineNoData',
}

export const Main = () => {
  const isLoggedIn = useAuthIsLoggedIn();
  const isOnline = useIsOnline();
  const properties = useProperties();
  const user = useGetUser();
  const noUserName = !user?.name || user.name === user.email;

  // Update html lang attribute
  useEffect(() => {
    if (user?.locale) {
      document.documentElement.lang = user.locale;
    }
  }, [user?.locale]);

  // Update favicon on staging
  useEffect(() => {
    if (window.location.hostname.startsWith('dolly-web-staging')) {
      document
        .querySelector('link[rel="icon"]')
        .setAttribute('href', stagingFavIcon);
    }
  }, []);

  // Load properties on mount
  useEffect(() => {
    if (isLoggedIn) {
      properties.refresh();
    }
  }, [isLoggedIn]);

  const appRouteState: AppRouteState = (() => {
    if (isLoggedIn) {
      if (properties.entities.length === 0) {
        if (isOnline) {
          return AppRouteState.LoggedInSetUp;
        }
        return AppRouteState.LoggedInOfflineNoData;
      }
      if (noUserName) {
        return AppRouteState.LoggedInUserHasNoDisplayName;
      }
      return AppRouteState.LoggedInReturningUser;
    }
    return AppRouteState.Unauthenticated;
  })();

  if (properties.loading) {
    return <LoadingScreen fullScreen />;
  }

  // const modals = [
  //   Screens.InventoryItemCreate,
  //   Screens.MobActionCreate,
  //   Screens.MobCreate,
  //   Screens.PaddockActionCreate,
  //   Screens.PaddockCreate,
  //   Screens.PaddockGroupCreate,
  //   Screens.PaddockGroupEdit,
  //   Screens.PaddockMergeMobs,
  //   Screens.PaddockMoveMob,
  //   Screens.PropertyCreate,
  //   Screens.RainGaugeCreate,
  //   Screens.RainGaugeReadingCreate,
  //   Screens.SettingsPropertyClassesTypesCreate,
  //   Screens.SettingsPropertyUsersInviteUser,
  //   Screens.TaskCreate,
  // ];

  switch (appRouteState) {
    case AppRouteState.LoggedInOfflineNoData:
      return <RouterLoggedInOfflineNoData />;
    case AppRouteState.Unauthenticated:
      return <RouterUnauthenticated />;

    case AppRouteState.LoggedInSetUp:
      return <RouterLoggedInSetUp />;

    case AppRouteState.LoggedInUserHasNoDisplayName:
      return <RouterLoggedInUserHasNoDisplayName />;

    case AppRouteState.LoggedInReturningUser:
      return <RouterLoggedInReturningUser role={user?.role} />;
  }
};

const ScreenRenderer = (props: ScreenRouteProps) => {
  const Component = props.component;
  const { setHeader, setStyle, setParams, drawerOpen, toggleDrawer } =
    useAppLayoutContext();
  const params = useParams();
  const location = useLocation();
  const paramsWithMode = {
    ...params,
  };

  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const { update } = useSettings();

  if (location.hash.length > 1) {
    paramsWithMode.mode = location.hash.slice(1);
  }

  const screenProps: ScreenRendererProps = {
    route: {
      params: paramsWithMode,
      location,
    },
    drawer: {
      isOpen: drawerOpen,
      toggle: toggleDrawer,
    },
  };

  useEffect(() => {
    if (!stockingUnit) {
      update({ stockingUnit: StockingUnit.DSE });
    }
  }, [stockingUnit]);

  useEffect(() => {
    const Header = props.header ?? null;
    setHeader(Header ? <Header {...(screenProps as any)} /> : null);
    setStyle({
      modal: Boolean(props.modal),
      noPadding: Boolean(props.noPadding),
    });
    setParams(paramsWithMode);

    return () => {
      setHeader(null);
    };
  }, [props.name, location, drawerOpen]);

  return <Component {...(screenProps as any)} />;
};

interface ScreenRouteProps {
  name: string;
  component: React.FC;
  header?: React.FC;
  modal?: boolean;
  noPadding?: boolean;
}

const makeScreenRoute = (props: ScreenRouteProps) => {
  return {
    path: props.name,
    element: <ScreenRenderer {...props} />,
  };
};

const makeDefaultRoute = (name: string, redirectTo: string) => ({
  path: '*',
  element: <Navigate to={toPath(name)} state={{ redirectTo }} />,
});

const makeRoutes = (
  routes: [ScreenRouteProps, ...ScreenRouteProps[]],
  role?: UserRole,
  redirectTo?: string
) => {
  const filteredRoutes = routes.filter((route) => {
    if (!role) {
      return true;
    }
    return ACCESS_MATRIX[route.name].includes(role);
  });
  return [
    ...filteredRoutes.map(makeScreenRoute),
    makeDefaultRoute(filteredRoutes[0].name, redirectTo),
  ];
};

const RouterLoggedInOfflineNoData = () => {
  const { refresh } = useProperties();

  return <OfflineNoDataScreen onRefresh={refresh} />;
};

const RouterUnauthenticated = () => {
  const location = useLocation();
  const redirectTo = location.key === 'default' ? location.pathname : null;
  const routes = makeRoutes(
    [
      Screens.SignIn,
      Screens.SignUp,
      Screens.ForgotPassword,
      Screens.InvitationSetUp,
    ],
    null,
    redirectTo
  );

  // Add legacy sign-up route redirect
  routes.push({
    path: '/signup',
    element: <Navigate to="/sign-up" replace />,
  });

  return <AppScreen>{useRoutes(routes)}</AppScreen>;
};

const RouterLoggedInSetUp = () => {
  const routes = useRoutes(makeRoutes([Screens.SetUpProperty]));
  return <AppScreen>{routes}</AppScreen>;
};

const RouterLoggedInUserHasNoDisplayName = () => {
  const routes = useRoutes(makeRoutes([Screens.SetUpDisplayName]));
  return <AppScreen>{routes}</AppScreen>;
};

const RouterLoggedInReturningUser = ({ role }: { role: UserRole }) => {
  // @TODO
  // Ideally the propertyId should be part of the route so that the user can
  // share/bookmark/etc urls that are always valid (no matter which property
  // is selected in the UI)
  // i.e. useRoutes({ path: ':propertyId/', children: makeRoutes([...])});

  const currentRoute = useLocation();
  const navigate = useNavigate();

  // Redirect user if route.state.redirectTo is set
  React.useEffect(() => {
    if (currentRoute.state?.redirectTo) {
      navigate(currentRoute.state.redirectTo, { replace: true });
    }
  }, [currentRoute.state?.redirectTo]);

  if (currentRoute.pathname === ROUTE_NAME.CALLBACK) {
    return (
      <ScreenRenderer
        name={Screens.Callback.name}
        component={Screens.Callback.component}
      />
    );
  }

  const routes = makeRoutes(
    [
      Screens.Dashboard, // first route is the default route
      //
      Screens.CasualtyCreate,
      Screens.CasualtyDetail,
      Screens.CasualtyEdit,
      Screens.Chat,
      Screens.Inventories,
      Screens.InventoryEdit,
      Screens.InventoryItemCreate,
      Screens.InventoryItemDetail,
      Screens.InventoryItemEdit,
      Screens.InventoryItems,
      Screens.InventoryItemBatchCreate,
      Screens.InventoryItemBatchDetail,
      Screens.InventoryItemBatchEdit,
      Screens.Map,
      Screens.MapAssetCreate,
      Screens.MapAssetDetail,
      Screens.MapAssetEdit,
      Screens.MapAssets,
      Screens.MobActionCreate,
      Screens.MobActionDetail,
      Screens.MobActionEdit,
      Screens.MobCreate,
      Screens.MobDetail,
      Screens.MobEdit,
      Screens.Mobs,
      Screens.NaturalIncreaseDetail,
      Screens.NaturalIncreaseEdit,
      Screens.PaddockActionCreate,
      Screens.PaddockActionDetail,
      Screens.PaddockActionEdit,
      Screens.PaddockCreate,
      Screens.PaddockDetail,
      Screens.PaddockEdit,
      Screens.PaddockGroupCreate,
      Screens.PaddockGroupEdit,
      Screens.PaddockMergeMobs,
      Screens.PaddockMoveMob,
      Screens.Paddocks,
      Screens.PurchaseDetail,
      Screens.PurchaseEdit,
      Screens.RainGaugeCreate,
      Screens.RainGaugeDetail,
      Screens.RainGaugeEdit,
      Screens.RainGaugeReading,
      Screens.RainGaugeReadingCreate,
      Screens.RainGaugeReadingEdit,
      Screens.RainGauges,
      Screens.ReferFarmer,
      Screens.Reports,
      Screens.SaleCreate,
      Screens.SaleDetail,
      Screens.SaleEdit,
      Screens.SettingsUser,
      Screens.SettingsUnits,
      Screens.Settings,
      Screens.SettingsBilling,
      Screens.SettingsPropertyInformation,
      Screens.SettingsPropertyClassesTypes,
      Screens.SettingsPropertyClassesTypesGroup,
      Screens.SettingsPropertyClassesTypesEdit,
      Screens.SettingsPropertyClassesTypesCreate,
      Screens.SettingsPropertyUsers,
      Screens.SettingsPropertyUsersUserEdit,
      Screens.SettingsPropertyUsersInviteUser,
      Screens.SettingsPropertyMobbleConnectUser,
      Screens.SettingsPropertyMobbleConnectUsers,
      Screens.SettingsPropertyMobbleConnectEditUser,
      Screens.SettingsPropertyMobbleConnectInviteUser,
      Screens.SettingsPropertyMobbleApps,
      Screens.Summaries,
      Screens.SummaryCasualties,
      Screens.SummaryMobActions,
      Screens.SummaryEvents,
      Screens.SummaryNaturalIncreases,
      Screens.SummaryPaddockActions,
      Screens.SummaryPurchases,
      Screens.SummarySales,
      Screens.TaskCreate,
      Screens.TaskDetail,
      Screens.TaskEdit,
      Screens.Tasks,
      Screens.PropertyCreate,

      Screens.ImportAgWorldPaddocks,
    ],
    role
  );

  // Add legacy billing route redirect
  routes.push({
    path: '/billing',
    element: <Navigate to="/settings/billing" replace />,
  });

  return <AppLayout>{useRoutes(routes)}</AppLayout>;
};
